import styled from "styled-components/macro";

export const StyledRangeInput = styled.input<{ size: number }>`
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &::-moz-focus-outer {
        border: 0;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: ${({ size }) => size}px;
        width: ${({ size }) => size}px;
        background-color: ${({ theme }) => theme.primary1};
        border-radius: 100%;
        border: none;
        transform: translateY(-50%);
        color: ${({ theme }) => theme.primary1};

        &:hover,
        &:focus {
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.08), 0 16px 24px rgba(0, 0, 0, 0.06), 0 24px 32px rgba(0, 0, 0, 0.04);
        }
    }

    &::-moz-range-thumb {
        height: ${({ size }) => size}px;
        width: ${({ size }) => size}px;
        background-color: #565a69;
        border-radius: 100%;
        border: none;
        color: ${({ theme }) => theme.bg1};

        &:hover,
        &:focus {
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.08), 0 16px 24px rgba(0, 0, 0, 0.06), 0 24px 32px rgba(0, 0, 0, 0.04);
        }
    }

    &::-ms-thumb {
        height: ${({ size }) => size}px;
        width: ${({ size }) => size}px;
        background-color: #565a69;
        border-radius: 100%;
        color: ${({ theme }) => theme.bg1};

        &:hover,
        &:focus {
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.08), 0 16px 24px rgba(0, 0, 0, 0.06), 0 24px 32px rgba(0, 0, 0, 0.04);
        }
    }

    &::-webkit-slider-runnable-track {
        background: linear-gradient(90deg, ${({ theme }) => theme.primary1}, ${({ theme }) => theme.primary2});
        height: 2px;
    }

    &::-moz-range-track {
        background: linear-gradient(90deg, ${({ theme }) => theme.bg5}, ${({ theme }) => theme.bg3});
        height: 2px;
    }

    &::-ms-track {
        width: 100%;
        border-color: transparent;
        color: transparent;

        background: ${({ theme }) => theme.bg5};
        height: 2px;
    }
    &::-ms-fill-lower {
        background: ${({ theme }) => theme.bg5};
    }
    &::-ms-fill-upper {
        background: ${({ theme }) => theme.bg3};
    }
`;
