import styled from "styled-components/macro";
import { ReactComponent as Close } from "../../assets/images/x.svg";
import Loader from "../Loader";
import { darken } from "polished";

//index
export const CloseIcon = styled.div`
    position: absolute;
    right: 1rem;
    top: 14px;
    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
`;
export const CloseColor = styled(Close)`
    path {
        stroke: #080064;
    }
`;
export const Wrapper = styled.div`
    ${({ theme }) => theme.flexColumnNoWrap}
    margin: 0;
    padding: 0;
    width: 100%;
`;
export const HeaderRow = styled.div`
    ${({ theme }) => theme.flexRowNoWrap};
    padding: 1rem 1rem;
    font-weight: 500;
    color: #080064;
    ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`;
export const ContentWrapper = styled.div`
    background-color: rgb(179, 230, 255);
    padding: 0 1rem 1rem 1rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0 1rem 1rem 1rem`};
`;
export const UpperSection = styled.div`
    position: relative;

    color: #080064;

    h5 {
        margin: 0 0 0.5rem;
        font-size: 1rem;
        font-weight: 400;
    }

    h5:last-child {
        margin-bottom: 0;
    }

    h4 {
        margin-top: 0;
        font-weight: 500;
    }
`;
export const OptionGrid = styled.div`
    display: grid;
    grid-gap: 10px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`;
export const HoverText = styled.div`
    text-decoration: none;
    color: ${({ theme }) => theme.text1};
    display: flex;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`;

//Option
const InfoCard = styled.button<{ active?: boolean }>`
    background-color: ${({ theme, active }) => (active ? theme.bg3 : theme.winterMainButton)};
    padding: 1rem;
    outline: none;
    border-radius: 12px;
    width: 100% !important;

    &:focus {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.winterDisabledButton};
    }

    border: 1px solid ${({ theme, active }) => (active ? theme.winterMainButton : theme.winterMainButton)};
`;
const OptionCard = styled(InfoCard as any)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 1rem;
`;
export const OptionCardLeft = styled.div`
    ${({ theme }) => theme.flexColumnNoWrap};
    justify-content: center;
    height: 100%;
`;
export const OptionCardClickable = styled(OptionCard as any)<{ clickable?: boolean }>`
    margin-top: 0;
    &:hover {
        cursor: ${({ clickable }) => (clickable ? "pointer" : "")};
        border: ${({ clickable }) => (clickable ? `1px solid rgb(0, 143, 255)` : ``)};
    }
    opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;
export const GreenCircle = styled.div`
    ${({ theme }) => theme.flexRowNoWrap}
    justify-content: center;
    align-items: center;

    &:first-child {
        height: 8px;
        width: 8px;
        margin-right: 8px;
        background-color: ${({ theme }) => theme.green1};
        border-radius: 50%;
    }
`;
export const CircleWrapper = styled.div`
    color: ${({ theme }) => theme.green1};
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const HeaderText = styled.div`
    ${({ theme }) => theme.flexRowNoWrap};
    color: ${(props) => (props.color === "blue" ? ({ theme }) => theme.primary1 : ({ theme }) => theme.text1)};
    font-size: 1rem;
    font-weight: 500;
`;
export const SubHeader = styled.div`
    color: ${({ theme }) => theme.text1};
    margin-top: 10px;
    font-size: 12px;
`;
export const IconWrapper = styled.div<{ size?: number | null }>`
    ${({ theme }) => theme.flexColumnNoWrap};
    align-items: center;
    justify-content: center;
    & > img,
    span {
        height: ${({ size }) => (size ? size + "px" : "24px")};
        width: ${({ size }) => (size ? size + "px" : "24px")};
    }
    ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`;

//PendingView
export const PendingSection = styled.div`
    ${({ theme }) => theme.flexColumnNoWrap};
    align-items: center;
    justify-content: center;
    width: 100%;
    & > * {
        width: 100%;
    }
`;
export const StyledLoader = styled(Loader)`
    margin-right: 1rem;
`;
export const LoadingMessage = styled.div<{ error?: boolean }>`
    ${({ theme }) => theme.flexRowNoWrap};
    align-items: center;
    justify-content: flex-start;
    border-radius: 12px;
    margin-bottom: 20px;
    color: ${({ theme, error }) => (error ? theme.red1 : "inherit")};

    & > * {
        padding: 1rem;
    }
`;
export const ErrorGroup = styled.div`
    ${({ theme }) => theme.flexRowNoWrap};
    align-items: center;
    justify-content: flex-start;
`;
export const ErrorButton = styled.div`
    border-radius: 8px;
    font-size: 12px;
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg4};
    margin-left: 1rem;
    padding: 0.5rem;
    font-weight: 600;
    user-select: none;

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => darken(0.1, theme.text4)};
    }
`;
export const LoadingWrapper = styled.div`
    ${({ theme }) => theme.flexRowNoWrap};
    align-items: center;
    justify-content: center;
`;

export const ConnectNetwork = styled.div`
    padding: 1rem;
    background: #6f58f6;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: ${darken(0.08, "#6F58F6")};
    }
`;
