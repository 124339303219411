import DEFAULT_TOKEN_LIST from "@uniswap/default-token-list";
import { TokenList } from "@uniswap/token-lists";
import { useMemo } from "react";
import { useAppSelector } from "state/hooks";
import sortByListPriority from "utils/listSort";
import { AppState } from "../index";
import { WrappedTokenInfo } from "./wrappedTokenInfo";
import { DEFAULT_ACTIVE_LIST_URLS } from "../../constants/lists";

export type TokenAddressMap = Readonly<{
    [chainId: number]: Readonly<{ [tokenAddress: string]: { token: WrappedTokenInfo; list: TokenList } }>;
}>;

type Mutable<T> = {
    -readonly [P in keyof T]: Mutable<T[P]>;
};

const listCache: WeakMap<TokenList, TokenAddressMap> | null = typeof WeakMap !== "undefined" ? new WeakMap<TokenList, TokenAddressMap>() : null;

function listToTokenMap(list: TokenList): TokenAddressMap {
    const result = listCache?.get(list);
    if (result) return result;

    const map = list.tokens.reduce<Mutable<TokenAddressMap>>((tokenMap, tokenInfo) => {
        const token = new WrappedTokenInfo(tokenInfo, list);
        if (tokenMap[token.chainId]?.[token.address] !== undefined) {
            console.error(`Duplicate token! ${token.address}`);
            return tokenMap;
        }
        if (!tokenMap[token.chainId]) tokenMap[token.chainId] = {};
        tokenMap[token.chainId][token.address] = {
            token,
            list,
        };
        return tokenMap;
    }, {}) as TokenAddressMap;
    listCache?.set(list, map);
    return map;
}

const TRANSFORMED_DEFAULT_TOKEN_LIST = listToTokenMap(DEFAULT_TOKEN_LIST);

export function useAllLists(): AppState["lists"]["byUrl"] {
    return useAppSelector((state) => state.lists.byUrl);
}

/**
 * Combine the tokens in map2 with the tokens on map1, where tokens on map1 take precedence
 * @param map1 the base token map
 * @param map2 the map of additioanl tokens to add to the base map
 */
export function combineMaps(map1: TokenAddressMap, map2: TokenAddressMap): TokenAddressMap {
    const chainIds = Object.keys(
        Object.keys(map1)
            .concat(Object.keys(map2))
            .reduce<{ [chainId: string]: true }>((memo, value) => {
                memo[value] = true;
                return memo;
            }, {})
    ).map((id) => parseInt(id));

    return chainIds.reduce<Mutable<TokenAddressMap>>((memo, chainId) => {
        memo[chainId] = {
            ...map2[chainId],
            // map1 takes precedence
            ...map1[chainId],
        };
        return memo;
    }, {}) as TokenAddressMap;
}

// merge tokens contained within lists from urls
function useCombinedTokenMapFromUrls(urls: string[] | undefined): TokenAddressMap {
    const lists = useAllLists();
    return useMemo(() => {
        if (!urls) return {};
        return (
            urls
                .slice()
                // sort by priority so top priority goes last
                .sort(sortByListPriority)
                .reduce((allTokens, currentUrl) => {
                    const current = lists[currentUrl]?.current;
                    if (!current) return allTokens;
                    try {
                        return combineMaps(allTokens, listToTokenMap(current));
                    } catch (error) {
                        console.error("Could not show token list due to error", error);
                        return allTokens;
                    }
                }, {})
        );
    }, [lists, urls]);
}

// filter out unsupported lists
export function useActiveListUrls(): string[] | undefined {
    return useAppSelector((state) => state.lists.activeListUrls);
}

export function useInactiveListUrls(): string[] {
    const lists = useAllLists();
    const allActiveListUrls = useActiveListUrls();
    return Object.keys(lists).filter((url) => !allActiveListUrls?.includes(url));
}

// get all the tokens from active lists, combine with local default tokens
export function useCombinedActiveList(): TokenAddressMap {
    const activeListUrls = useActiveListUrls();
    return useCombinedTokenMapFromUrls(activeListUrls);
    // combineMaps(activeTokens, TRANSFORMED_DEFAULT_TOKEN_LIST)
}

// list of tokens not supported on interface, used to show warnings and prevent swaps and add

export function useIsListActive(url: string): boolean {
    const activeListUrls = useActiveListUrls();
    return Boolean(activeListUrls?.includes(url));
}
